.homepage__top {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-items: flex-start;
    padding: 2rem 0 2rem 2rem;
}

.homepage__profolio__container {
    display: flex;
}

.homepage__top > * {
    margin: 1rem 0 1rem 1rem;
}

.homepage__article_container {
    display: flex;
    max-width: 60%;
}
@media only screen and (max-width: 900px) {
    .homepage__top {
        flex-direction: column;
    }

    .homepage__article_container {
        max-width: 75%;
    }

}

.h__spacer {
    flex-grow: 1;
}
