.feature__container {

    padding: 1rem;
    border-radius: 5px;

    /* ff 3.6+ */
    background:-moz-radial-gradient(ellipse at 100% 0%, rgba(28, 113, 250, 1) 0%, rgba(1, 38, 76, 1) 50%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(ellipse at 100% 0%, rgba(28, 113, 250, 1) 0%, rgba(1, 38, 76, 1) 50%);

    /* opera 11.10+ */
    background:-o-radial-gradient(ellipse at 100% 0%, rgba(28, 113, 250, 1) 0%, rgba(1, 38, 76, 1) 50%);

    /* ie 10+ */
    background:-ms-radial-gradient(ellipse at 100% 0%, rgba(28, 113, 250, 1) 0%, rgba(1, 38, 76, 1) 50%);

    /* global 92%+ browsers support */
    background:radial-gradient(ellipse at 100% 0%, rgba(28, 113, 250, 1) 0%, rgba(1, 38, 76, 1) 50%);
}

.feature__header_1 {
    font-size: 30px;
    text-transform: capitalize;
    background-clip: text;
    background-color: var(--color-header);
    -webkit-text-fill-color: transparent;
}

.feature__header_2 {
    font-size: 25px;
    background-clip: text;
    background-color: var(--color-header);
    -webkit-text-fill-color: transparent;
}

.feature__text {
    font-family: var(--font-family);
    font-size: larger;
    background-clip: text;
    background-color: var(--color-text);
    -webkit-text-fill-color: transparent;
}

.feature__bar {
    width: 100px;
    height: 3px;
    background: var(--gradient-text);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.feature__hyperlink {
    background-color: var(--color-header);
    background-clip: text;
}

