.project__page__container {
    display: flex;
    margin: 2rem;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}
.project__page {
    background-color: var(--color-bg-second);
    display: flex;
    max-width: 60%;
    margin: 2rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: var(--color-subtext);
    font-size: xx-Large;
}