.navbar__text {
    background-clip: text;
    background-color: var(--color-header);
    -webkit-text-fill-color: transparent;
    padding-left: 5px;
    font-size: x-large;
}


.navabar__container {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar__main {
    width: 100%;
}

.navbar__link {
    display: flex;
    padding:0.5rem 1rem 0.5rem 1rem;
    margin: 0 1rem;
    border-radius: 10px;
    transition: all .2s ease-in-out;
}



.navbar__link:hover {
    transform: scale(1.2);
}

@media only screen and (max-width: 763px) {
    .navbar__link {
        margin: 0 0.5rem;
    }
}
