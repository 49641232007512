*   {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background-color: var(--color-bg);
    overflow-x: hidden;
}

a {
    color: unset;
    text-decoration: none;
}

.gradient__bg {
    /* ff 3.6+ */
    background:-moz-linear-gradient(180deg, rgba(18, 69, 125, 1) 6%, rgba(4, 12, 24, 1) 95%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(180deg, rgba(18, 69, 125, 1) 6%, rgba(4, 12, 24, 1) 95%);

    /* opera 11.10+ */
    background:-o-linear-gradient(180deg, rgba(18, 69, 125, 1) 6%, rgba(4, 12, 24, 1) 95%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#040C18', endColorstr='#12457D', GradientType=1 );

    /* ie 10+ */
    background:-ms-linear-gradient(180deg, rgba(18, 69, 125, 1) 6%, rgba(4, 12, 24, 1) 95%);

    /* global 94%+ browsers support */
    background:linear-gradient(180deg, rgba(18, 69, 125, 1) 6%, rgba(4, 12, 24, 1) 95%);

}

.gradient__text {
    background-color: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margins {
    margin: 4rem 6rem;
}

.v_spacer {
    flex-grow: 1;
}

.max__height {
    height: 100vh;
}

/* support for tablets */
@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }

    .section__margins {
        margin: 4rem;
    }
}


/* support for phones */
@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem 2rem;
    }

    .section__margins {
        margin: 4rem 2rem;
    }
}

